/**
 * Available vars: https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
 */

@import '~antd/dist/antd.dark.less';
@import './common/vars.os.less';
@import './common/polyfill.os.less';
@import './common/typography.os.less';

// Colors
@primary-color: @PRIMARY;
@info-color: @INFO;
@success-color: @SUCCESS;
@warning-color: @WARNING;
@error-color: @ERROR;
@popover-background: @NEUTRAL100;
@body-background: @NEUTRAL100;
@component-background: @NEUTRAL100;
@disabled-bg: @NEUTRAL90;
@item-hover-bg: @NEUTRAL90;

// Typography
@font-family: 'Open Sans', sans-serif;
@text-color: @NEUTRAL05;
@text-color-secondary: @NEUTRAL40;
@heading-color: @NEUTRAL05;
@disabled-color: @NEUTRAL60;
@typography-title-margin-top: 0px;
@typography-title-margin-bottom: 0px;

// Border
@border-radius-base: 8px;
@border-color-base: @NEUTRAL80;
@border-width-base: 1px;
@border-color-split: @border-color-base;
@popover-customize-border-color: @border-color-base;

// Card
@card-radius: @border-radius-base * 2;
@card-actions-background: @NEUTRAL100;
@card-background: @NEUTRAL100;
@card-shadow: @DARK_SHADOW2;

// Modal
@modal-header-bg: @NEUTRAL100;
@modal-content-bg: @NEUTRAL100;
@modal-footer-bg: @NEUTRAL100;
@modal-footer-padding-horizontal: 24px;

// Drawer
@drawer-bg: @NEUTRAL100;

// Button
@btn-font-weight: 700;
@btn-shadow: unset;
@btn-primary-shadow: unset;
@btn-text-shadow: unset;
@btn-default-border: @NEUTRAL80;
@btn-default-ghost-color: @NEUTRAL10;
@btn-default-ghost-border: @NEUTRAL10;

// Input
@input-placeholder-color: @NEUTRAL40;

// Divider
@divider-color: @NEUTRAL90;
@divider-orientation-margin: 0;

// Checkbox
@checkbox-border-radius: @border-radius-base - 4;

// Patch
.@{ant-prefix}-layout {
  background: unset;
}
.@{ant-prefix}-card {
  box-shadow: @LIGHT_SHADOW1;
  &.glass {
    background-color: color(~`('@{card-background}aa') `);
    backdrop-filter: blur(16px);
  }
}
.@{ant-prefix}-typography {
  &.primary {
    color: @PRIMARY;
  }
}
.@{ant-prefix}-card {
  box-shadow: @LIGHT_SHADOW1;
  &.glass {
    background-color: color(~`('@{card-background}66') `);
    backdrop-filter: blur(16px);
  }
}

// System
body {
  background-image: url('../images/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
