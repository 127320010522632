html {
  height: auto;
  min-height: 100%;
  scroll-behavior: smooth;
}

#root {
  min-height: 100%;
}

*:focus {
  outline: none !important;
}
