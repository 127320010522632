.base-btn {
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
  padding: 0px 24px;
  overflow: hidden;
  position: relative;
  & * {
    color: #ffffff !important;
  }
  &:hover {
    opacity: 0.8;
  }

  &.chain-btn {
    height: 2.8rem;
    border: none;
    text-align: center;
  }

  &.wallet-btn {
    height: 3.5rem;
    border: none;
    text-align: start;

    .bg-img {
      height: 4rem;
      opacity: 0.25;
      position: absolute;
      top: -0.25rem;
      right: 0.25rem;
    }
  }

  &.social-btn {
    height: 2.8rem;
    border: solid 1px #2eb835;
    background: transparent;
    text-align: center;
  }
}
